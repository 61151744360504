import React, { useState } from 'react'
import { FiLoader } from 'react-icons/fi'

export default function Button({ onClick, href, children, style, className, variant = 'default', Icon, onClickDisabled, disabled, soon = false }) {
  const [loading, setLoading] = useState(false)
  const iconStyles = {
    default: 'stroke-2 mr-2 -ml-1 -mt-1 block',
    primary: 'mr-2 -ml-1 -mt-1',
    secondary: 'stroke-2 text-lg mr-3 -ml-1 -mt-2',
    small: 'stroke-2 text-lg mr-3 -ml-1 -mt-2',
    icon: 'stroke-2 text-white w-full h-full',
    'icon-white': 'stroke-1  w-full h-full'
  }
  const styles = {
    default: `text-2xl font-bold py-3 px-6 lg:px-10 text-white uppercase bg-black hover:bg-orange-dark`,
    transparent: `text-lg py-3 px-6 lg:px-10 text-black uppercase bg-white hover:bg-pink-light`,
    primary: `text-lg font-normal py-3 px-8 text-white bg-orange hover:bg-orange-dark`,
    secondary: `text-lg py-3 px-6 lg:px-10 text-white uppercase bg-black hover:bg-orange-dark`,
    unselected: `text-lg py-2 px-5 lg:px-10 text-black border border-gray-200 hover:border-gray-300 hover:text-gray-500 uppercase bg-white`,
    small: `text-base pt-4 pb-2 px-4 lg:px-6 text-white uppercase bg-black hover:bg-orange-dark`,
    'secondary-white': `text-sm font-normal py-2 px-5 text-gray-400 bg-white border border-gray-200 hover:border-gray-300 hover:text-gray-500`,
    white: `text-base font-normal py-2 px-6 text-orange bg-white hover:text-orange-dark`,
    icon: `h-8 w-8 p-2 text-white bg-pink hover:bg-pink-light`,
    'icon-white': `h-6 w-6 font-light p-2 relative box-content text-orange bg-white rounded-full hover:text-orange-dark`,
  }
  return (
    <a href={href} target={href && href.match('http') ? '_blank' : ''}>
      <button
        onClick={async () => {
          if (disabled && onClickDisabled) return onClickDisabled()
          if (disabled || !onClick) return
          setLoading(true)
          try {
            await onClick()
          } catch (error) {
            console.error(error)
            setLoading(false)
          }
          setLoading(false)
        }}
        disabled={loading}
        style ={{...style, fontFamily: 'PPFormula', letterSpacing: 1, paddingTop: (variant !== 'icon' && variant !== 'small') && 18, paddingBottom: (variant !== 'icon' && variant !== 'small') && 10, fontSize: variant === 'default' && '1.3125rem', lineHeight: '1.125rem', color: variant !== 'unselected' && '#F9F4EC' }}
        className={`relative flex overflow-hidden justify-center items-center align-middle focus:outline-none select-none flex-shrink-0 ${styles[variant]} ${className} ${(disabled) && 'opacity-30 hover:bg-black'}`}>
        {/* {Icon && <Icon className={iconStyles[variant]} />} */}
        {children}
        {loading &&
          <div className={`absolute top-0 w-full h-full p-2 text-black`} style={{ backgroundColor: '#F9F4EC' }}>
            <FiLoader className="stroke-1 rotating box-border w-full h-full" />
          </div>
        }
        {soon &&
          <div className="opacity-0 hover:opacity-100 flex absolute w-full h-full bg-white justify-center items-center">Bientôt disponible</div>
        }
      </button>
    </a>
  )
}
